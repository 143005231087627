<template>
  <router-link :to="`${ '/admin/tech-support/' + data.id }`" class="tech-support-card card">
    <div class="card__info">
      <p class="card__info-id">{{ $t('request', {data: data.id || null }) }}</p>
      <p class="card__info-date">{{ getFullDate(data.createdAt) || null }}</p>
    </div>
    <div class="card__status">
      <span class="card__status-round"></span>
      <p class="card__status-text">{{ $t('new-2') }}</p>
    </div>
    <p class="card__description">{{ data.subject }}</p>
    <p :class="['card__username', { anonymous: anonymous }]">{{ username }}</p>
  </router-link>
</template>

<script>
import { getFullDate } from '@/utils/general'
export default {
  name: "TechSupportCard",

  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      anonymous: false,
      name: 'Ekaterina_fad',
      getFullDate
    }
  },

  computed: {
    username() {
      return this.anonymous ? this.$t('anonymous') :
          this.data.createdBy?.lastName ?
              this.data.createdBy?.lastName + ' ' + this.data.createdBy?.firstName : this.$t('empty')
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 27px 67px 27px 62px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 200px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 24px 13px 23px 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-id {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #E21F1F;

      @media (max-width: 1200px) {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &-date {
      font-weight: 400;
      font-size: 14px;
      line-height: 120.2%;
      color: #343432;

      @media (max-width: 1200px) {
        font-size: 12px;
        line-height: 11px;
        color: #9A9A9A;
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &-round {
      width: 10px;
      height: 10px;
      background: #FD9F32;
      box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
      border-radius: 200px;
    }

    &-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: #343432;
    }
  }

  &__description {
    max-width: 328px;
    font-size: 14px;
    line-height: 120.2%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #343432;
    word-break: break-all;

    @media (max-width: 1200px) {
      font-size: 12px;
      line-height: 112.7%;
    }
  }

  &__username {
    font-size: 16px;
    line-height: 19px;
    color: #E21F1F;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 768px) {
      display: none;
    }

    &.anonymous {
      color: #343432;
    }
  }
}
</style>